<template>
  <div id="home">
    <div class="h-banner">
      <div class="h-title" :style="{fontSize: $i18n.locale === 'zh-cn' ? '66px' : '72px', lineHeight: $i18n.locale === 'zh-cn' ? '70px' : '65px'}">
        {{$t('Daollar.Banner._1')}}
      </div>
      <div class="h-title1">
        {{$t('Daollar.Banner._2')}}
      </div>
    </div>
    <div class="h-part1">
      <div class="part1-item">
        <div class="item">
          <div class="itemBlue">
            <img src="../Home/images/roc.svg" />
            ROC
          </div>
          <div class="item-right">
            <!-- <router-link to="/legal/disclaimer">ROC sale disclaimer</router-link> -->
          </div>
        </div>
        <router-link  to="/legal/token" class="btn btn-primary btn-large d-block">View Issuance Plan</router-link>
      </div>
      <div class="part1-item">
        <div class="item">
          <div class="itemBlue">
            <img src="../Home/images/do.svg" />
            DO
          </div>
          <div class="item-right">
             <span>1</span>
            <span> DO</span>
            <span> ≈ </span>
            <span>{{(1/(rocPrice/2)).toFixed(2)}}</span>
            <span> ROC</span>
          </div>
        </div>
        <!--<router-link to='/wallet?type=do' class="btn btn-primary btn-large d-block">{{$t('Daollar.Banner._3')}}</router-link>-->
        <span class="btn btn-primary btn-large d-block" style="padding: 12px 20px;background: #9fa1a4; border: #9fa1a4">{{$t('Daollar.Banner._3')}}</span>
      </div>
    </div>
    <div class="p3">
      <div class="p3-content">
        <!-- <div class="title">Roxe Cash (ROC)</div> -->
        <div class="text">{{$t('Daollar.Banner._4')}}</div>
        <div class="text">{{$t('Daollar.Banner._5')}}</div>
      </div>
      <div class="p3-bottom">
        <router-link class="read2" target="_blank" to="/Daollar Protocol White Paper.pdf">{{$t('White')}}</router-link>
        <router-link :style="{marginLeft:'20px'}" class="read2" target="_blank" to="/Daollar-Protocol-Smart-Contract-Audit-Report.pdf">{{$t('Audit')}}</router-link>
      </div>
    </div>

    <div class="p1">
      <div class="p1-content">
        <div class="left">
          <div class="title"><img src="../../assets/img/roxe-cash.svg" />{{$t('Daollar.Row_1._1')}}</div>
          <div class="text">{{$t('Daollar.Row_1._2')}}</div>
          <div class="text">{{$t('Daollar.Row_1._3')}}</div>
        </div>
        <div class="right">
          <div class="title"><img src="../../assets/img/do-cash.svg" />{{$t('Daollar.Row_1._4')}}</div>
          <div class="text">{{$t('Daollar.Row_1._5')}}</div>
        </div>
      </div>
      <div class="p1-bottom">
        <router-link class="read" target="_blank" to="/Roxe Chain White Paper.pdf">{{$t('Whitepaper')}}</router-link>
        
      </div>
    </div>

    <div class="p2">
      <div class="p2-content">
        <div class="title">{{$t('Daollar.Row_2._1')}}</div>
        <div class="text">{{$t('Daollar.Row_2._2')}}</div>
        <div class="text">{{$t('Daollar.Row_2._3')}}</div>
        <div class="bg-text">
          {{$t('Daollar.Row_2._4')}}
        </div>
        <div class="text">{{$t('Daollar.Row_2._5')}}</div>
        <div class="bg-text">
          <div class="yellow"><span></span>{{$t('Daollar.Row_2._6')}}</div>
          <div class="yellow"><span></span>{{$t('Daollar.Row_2._7')}}</div>
          <div class="yellow"><span></span>{{$t('Daollar.Row_2._8')}}</div>
          <div class="yellow"><span></span>{{$t('Daollar.Row_2._9')}}</div>
        </div>
      </div>
    </div>
    <!-- <roxe-footer></roxe-footer> -->
  </div>
</template>

<script>
import {
  RocPrice,
  getDisplayBalance,
  BigNum
} from "../../unitls"
export default {
  name: "Home",
   data() {
    return {
      rocPrice: 0, // 设置roc的价格 来计算roc兑换do的比例
    }
  },
  mounted() {
    // 获取roc的平均发行价格；
    setTimeout(() => {
      RocPrice().then(data => {
        this.rocPrice = getDisplayBalance(BigNum(data))
      })
    })
    
  },
  methods: {},
};
</script>
<style lang="less" scoped>
#home {
  .h-banner {
    height: 415px;
    background: linear-gradient(180deg, #085da3 0%, #3a89ca 100%);
    overflow: hidden;
    color: #ffffff;
    position: relative;
    .h-title {
      font-family: ParmaPetit;
      font-size: 80px;
      line-height: 100.3%;
      text-align: center;
      margin: 160px 0px 20px;
    }
    .h-title1 {
      font-family: Louis George Cafe;
      font-size: 26px;
      line-height: 20px;
      text-align: center;
    }
  }
  .h-part1 {
    width: 730px;
    height: auto;
    position: absolute;
    top: 380px;
    left: 50%;
    // margin-left: -365px;
    display: flex;
    transform: translate(-50%);
    // display: none;
    justify-content: space-between;
    .part1-item {
      width: 350px;
      background-color: white;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
      height: 160px;
      padding: 30px;
      &:first-child {
        margin-bottom: 20px;
      }
      .item {
        display: flex;
        justify-content: space-between;
        .itemBlue {
          display: inline-block;
          font-family: Louis George Cafe;
          font-size: 24px;
          line-height: 28px;
          color: #085da3;
          img {
            margin-top: -6px;
          }
        }
        .item-right {
          font-family: Louis George Cafe;
          font-size: 16px;
          line-height: 140%;
          color: #4f4f4f;
          margin-top: 3px;
        }
      }
      .btn {
        margin-top: 25px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .p1 {
    background: #F6F8FB;
    padding-bottom: 60px;
    .p1-content {
      max-width: 1110px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding-top: 64px;
      .left,.right {
        max-width: 540px;
      }
      .title {
        font-family: ParmaPetit;
        font-style: normal; 
        font-weight: normal;
        font-size: 38px;
        line-height: 100.3%;
        color: #085DA3;
        margin-bottom: 20px;
        display: flex;
        justify-items: center;
        img {
          margin-right: 10px;
        }
        
      }
      .text {
        font-family: Louis George Cafe;
        font-size: 16px;
        line-height: 20px;
        color: #818E9E;
        margin-bottom: 10px;
      }
    }
    .p1-bottom {
      margin-top: 40px;
      // margin-bottom: 70px;
      display: flex;
      justify-content: center;
      .read {
        width: 260px;
        height: 44px;
        background: #4AACFD;
        border: 1px solid #4AACFD;
        margin-left: 20px;
        display: inline-block;
        text-align: center;
        line-height: 44px;
        color: #fff;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .p2 {
    // background: #F6F8FB;
    padding-top: 70px;
    // padding-bottom: 87px;
    .p2-content {
      margin-bottom: 80px;
      .title {
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 100.3%;
        text-align: center;
        color: #085DA3;
        margin-bottom: 20px;
        line-height: 150%;
        
      }
      .text {
        max-width: 540px;
        font-family: Louis George Cafe;
        font-size: 16px;
        line-height: 20px;
        color: #818E9E;
        margin: 0 auto;
        margin-bottom: 16px;
      }
      .bg-text {
        margin: 0 auto;
        max-width: 540px;
        background: #F8FAFC;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        padding: 15px;
        font-family: Louis George Cafe;
        font-size: 16px;
        line-height: 20px;
        color: #818E9E;
        margin-bottom: 16px;
        .yellow {
          display: flex;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0px;
          }
          span {
            width: 10px;
            height: 10px;
            color: yellow;
            display: block;
            background: #ff75007a;
            // position: absolute;
            border-radius: 20px;
            margin-right: 10px;
            margin-top: 3px;
            // left: -20px;
          }
        }
      }
    }
  }

  .p3 {
    // padding-top: 55px;
    padding-bottom: 87px;
    padding-top: 200px;
    .p3-content {
      margin-bottom: 60px;

      // .title {
      //   font-family: ParmaPetit;
      //   font-style: normal;
      //   font-weight: normal;
      //   font-size: 38px;
      //   line-height: 100.3%;
      //   text-align: center;
      //   color: #085DA3;
      //   margin-bottom: 20px;
      // }
      .text {
        max-width: 540px;
        font-family: Louis George Cafe;
        font-size: 16px;
        line-height: 20px;
        color: #828282;
        margin: 0 auto;
      }
    }
    .p3-bottom {
        justify-content: center;
        display: flex;
        .read1,.read2 {
          padding: 13px 20px;
          background: #2F98EE;
          color: #fff;
          display: block;
          &:hover {
            opacity: 0.8;
          }
        }
        .read1 {
          margin-right: 60px;
        }

      }
  }
}
@media screen and (min-width: 320px) and (max-width: 1210px) {
  #home .h-banner {
    .h-title {
      font-size: 40px!important;
      line-height: 40px!important;
    }
  }
  #home .h-part1 {
    flex-wrap: wrap;
    width: auto;
    justify-content: center;
  }
  #home  .p1 {
    padding-left: 50px;
    padding-right: 50px;
    
    .p1-content {
      flex-wrap: wrap;
      padding-top: 64px;
      .left, .right {
        max-width: none;
      }
      .right {
        margin-top: 50px;
      }
    }
  }
  #home .p3 {
    padding-left: 50px;
    padding-right: 50px;
    .p3-content {
      padding-top:170px;
      .text {
        max-width: none;
      }
    }
  }
  #home  .p2 {
    padding-left: 50px;
    padding-right: 50px;
    flex-wrap: wrap;
    width: auto;
    justify-content: center;
    .p2-content {
      .text {
        max-width: none;
      }
      .bg-text {
        max-width: none;
      }
    }
    .p2-bottom {
      flex-wrap: wrap;
      align-items: flex-start;
      .read1 {
        margin-right: 0px;
        margin-bottom: 30px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  
}

</style>
